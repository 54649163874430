// src/CustomButton.js
import React from 'react';
import './SecondaryButton.css'; // Import the CSS file for common styles

const SecondaryButton = ({ text, width, height, borderRadius, onLoginClick }) => {
    const buttonStyle = {
        width: width || 'auto',  // Default to 'auto' if no width is provided
        height: height || 'auto',
        backgroundColor: 'transparent', // Make background transparent
        // Default to 'auto' if no height is provided, // Primary blue background
        color: '#256EFB',          // White text
        border: '1px solid #256EFB',       
        borderRadius: borderRadius,      // Rounded corner
        fontSize: '1.05rem',            // Font size
        fontFamily: 'Noto Sans, sans-serif',
        fontWeight: 600,
        cursor: 'pointer',        // Pointer cursor on hover
        transition: 'background-color 0.2s ease, transform 0.2s ease',
        transform: 'scale(1)' // Smooth transitions
      };

      const hoverStyle = {
        backgroundColor: 'rgba(37, 110, 251, 0.06)', // Light background on hover
        transform: 'scale(1.05)',       // Slightly enlarge on hover
      };
    
      const activeStyle = {
        backgroundColor: 'rgba(37, 110, 251, 0.12)', // Darker background when clicked
        transform: 'scale(0.95)',       // Slightly shrink on click
      };

  return (
    <button
      className='primary-button'
      style={buttonStyle}
      onMouseOver={e => Object.assign(e.target.style, hoverStyle)}
      onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
      onMouseDown={e => Object.assign(e.target.style, activeStyle)}
      onMouseUp={e => Object.assign(e.target.style, hoverStyle)}
      onClick={onLoginClick}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
