import React from 'react';
import './SubscriptionForm.css'; // Import the CSS file for common styles


const SubscriptionForm3 = ({width, height, feedback, setFeedback}) => {
  const inputStyle = {
    width: '100%',
    height: '100%',
    paddingTop: '15px',          // Full width input
    paddingLeft: '20px',         // Padding inside the input box
    fontSize: '1rem',  
    border: 'none',      // Font size for the text
    borderRadius: '12px',     // Rounded corners // Light gray border
    backgroundColor: 'white', // White background color
    color: '#021E3E',
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 500,
    zIndex:4,
    display: 'flex',
    alignItems: 'flex-start' /* Align items to the top */,
    flexDirection: "column",
    lineHeight: '1.2',
    verticalAlign: 'top'
  };

  const formStyle = {
    width: '100%',  // Default to 100% if no width is provided
    height: '100%', // Default to 100% if no height is provided
    display: 'flex',
    alignItems: 'flex-start' /* Align items to the top */,
    flexDirection: 'column'
  };

  return (
    <form style={formStyle}>
      <style>
        {`
          input::placeholder {
            color: '#021E3E';
            opacity: 1;
          }
        `}
      </style>
      <textarea
        type="text"
        value={feedback}
        placeholder="Enter your desired activities, expectations or general feedback!"
        style={inputStyle}
        onChange={(e) => setFeedback(e.target.value)}
      />
    </form>
  );
};

export default SubscriptionForm3;