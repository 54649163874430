import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './VisionPage.css';
import Secundary from './assets/secondary_blue1.svg';
import FIcon1 from './assets/twitter1.png';
import FIcon2 from './assets/linkedin1.png';
import FIcon3 from './assets/facebook1.png';
import FIcon4 from './assets/instagram1.png';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { Link } from 'react-router-dom';
import Tcards from './assets/vision_title_cards.png'
import FSecundary from './assets/secondary_blue2.svg';
import Hcurl1 from './assets/helpus_curl_1.svg';
import SubscriptionForm from './SubscriptionForm';
import TertiaryButton from './TertiaryButton';

import { db } from './firebase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore';



const VisionPage = ({onLoginClick} ) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'emailFeedback'), {
        email: email,
        timestamp: new Date()
      });
      console.log('Document written with ID: ', docRef.id);
      setEmail('');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="App">
     <section id='title-page' className="title-page">
        <div className="title-page-container">
          <div className="title-secundary-container">
          <Link to="/">
            <img src={Secundary} alt="title Secundary" className="title-secundary" />
            </Link>
          </div>
          <div className="title-nav-top-container">
            <div className="title-nav-about-cont">
            <Link to="/#title-page" className="nav-link">Home</Link>
            </div>
            <div className="title-nav-gallery-cont">
            <Link to="/#howto-page" className="nav-link">About</Link>
            </div>
            <div className="title-nav-vision-cont">
              <Link to="/#socials-page" className="nav-link">Gallery</Link>
            </div>
            <div className="title-nav-contact-cont">
              <a href="#" className="nav-link">Our vision</a>
            </div>
          </div>
          <div className="title-nav-buttons-container">
          <SecondaryButton text="Login" width="8.3vw" height="5.10vh" borderRadius={"8px"} onLoginClick={onLoginClick} />
          <PrimaryButton text="Sign up" width="8.5vw" height="5.10vh" borderRadius={"8px"} onLoginClick={onLoginClick} />
          </div>
        </div>
        <div className="title-header-cont">
          <span className="title-header">Our vision</span>
        </div>
        <div className="title-desc-cont">
          <p className="title-desc">In a world of 8 billion people, it should be easy to make meaningful connections, yet many find it challenging. A lack of social interactions often is a major factor in unhappiness, and our increasingly digital lives can sometimes make it harder to <span className='highlight'>connect in real life</span>. Admitting you want to make new friends or join established groups can feel daunting.
<br></br><br></br>At Jointly, we have a clear mission: First, we aim to leverage the benefits and scalability of the digital world to <span className='highlight'>enhance social well-being </span>in the real world, without financial barriers. Creating or joining activities will always be free for non-business users. Second, we aim to foster a <span className='highlight'>community where everyone feels welcome</span>. Whether through the organized events or, hopefully in the future, at a Jointly venue in your city, we want to create spaces where you can interact freely and comfortably, without added social pressure.
<br></br><br></br><span className='highlight'>Create your activity </span> and join us in turning this vision into reality and help us make connecting with others a natural, enjoyable experience for everyone.</p>
        </div>
        <div className="title-cards-container2">
            <img src={Tcards} alt="Jointly App is a tool to broaden your social circle and to help relieve loneliness" className="title-cards2" />
        </div>
        <div className="blurred-circle"></div>
      </section>
      

      <section className="helpus-page">
        <div className="helpus-page-container">
          <div className="helpus-curl1-container">
            <img src={Hcurl1} alt="HelpUs Curl1" className="helpus-curl1" />
          </div>
          <div className="helpus-header-cont">
            <span className="helpus-header">Help us!</span>
          </div>
          <div className="helpus-desc-cont">
            <p className="helpus-desc">The Jointly journey has only just started and we need your help. Let us know if we can contact you to ask for your input and if we can keep you up to date.</p>
          </div>
          <div className="helpus-form-cont">
              <div className="helpus-form-subscription-cont">
                <SubscriptionForm width="64.28%" height="100%" email={email} setEmail={setEmail}/>
              </div>
              <TertiaryButton text="Contact me!" width="33.42%" height="100%" borderRadius={"12px"} onLoginClick={handleSubmit} />
          </div>
          <div className="helpus-blurred-circle"></div>


        </div>
      </section>


      <section className="footer-page">
        <div className="footer-page-container">
          <div className="footer-secundary-container">
            <img src={FSecundary} alt="title Secundary" className="footer-secundary" />
          </div>
          <div className="footer-desc-cont">
            <p className="footer-desc">Jointly makes building connections natural and easy. Our goal is to help you have a
            great time doing activities you love while meeting like-minded people.</p>
          </div>
          <div className="footer-copy-cont">
            <p className="footer-copy">@2024 Jointly. All rights reserved.</p>
          </div>
          <div className="footer-links-cont">
          <Link to='/#title-page' className="footer-link">Home</Link>
            <Link to='/#howto-page' className="footer-link">About</Link>
            <Link to='/#socials-page' className="footer-link">Gallery</Link>
            <Link to='/ourvision#title-page' className="footer-link">Our vision</Link>
          </div>
          <div className="footer-icon1-container">
            <div className="footer-icon2-container">
              <img src={FIcon1} alt="title Secundary" className="footer-icon" />
              <img src={FIcon2} alt="title Secundary" className="footer-icon" />
            </div>
            <div className="footer-icon2-container">
              <img src={FIcon3} alt="title Secundary" className="footer-icon" />
              <img src={FIcon4} alt="title Secundary" className="footer-icon" />
            </div>
          </div>
          <div className="footer-terms-cont">
            <p className="footer-copy">Terms and conditions</p>
            <div className="footer-spacer"></div>
            <p className="footer-copy">Privacy policy</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VisionPage;