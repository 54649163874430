import React, { useEffect } from 'react';
import './Banner.css'; // You'll need to create this CSS file

const Banner = ({ message, duration, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration); // Close the banner after the duration
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [duration, onClose]);

  return (
    <div className='bannerCont'>
        <div className="banner">
            {message}
        </div>
        <div className='loaderCont'>
        <div className="loader"></div>
        </div>
        </div>
  );
};

export default Banner;
