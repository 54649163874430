import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBT2-Ehmc2TLfToSKCCfzpVenHwKg_R_gU",
  authDomain: "jointly-lp.firebaseapp.com",
  projectId: "jointly-lp",
  storageBucket: "jointly-lp.appspot.com",
  messagingSenderId: "274980191481",
  appId: "1:274980191481:web:5b64d713bc8075fe01b628",
  measurementId: "G-MTCEMGHMQY"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = getFirestore(app);

// Export the auth module if you need it elsewhere
window.firebase = firebase;


export {db, auth};
export default app;

