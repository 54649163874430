import React,{ useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { auth } from './firebase'; // Import the initialized auth instance
import ProtectedRoute from './ProtectedRoute';


// Import your components/pages
import HomePage from './HomePage';
import VisionPage from './VisionPage';
import AuthPopup from './AuthPopup';
import PreregistrationPage from './PreregistrationPage';

import MHomePage from './MHomePage';


const App = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Set user when logged in
      } else {
        setUser(null); // Clear user when not logged in
      }
      setLoading(false)
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  return ( !loading && (
    <Router>
      <div className="app-container">
      <AuthPopup isVisible={isPopupVisible} onClose={hidePopup} />
      <Routes>
        <Route index element={isDesktop ? <HomePage onLoginClick={showPopup} /> : <MHomePage />} />
        <Route path="/ourvision" element={<VisionPage onLoginClick={showPopup}/>} />
        <Route path="/preregistration" element={
          <ProtectedRoute user={user}>
          <PreregistrationPage />
          </ProtectedRoute>} />
      </Routes>
      </div>
    </Router>)
  );
};

export default App;
