import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from './firebase'; // Import auth for sign-out example
import './MHomePage.css';
import { Link } from 'react-router-dom';
import Secundary from './assets/secondary_blue1.svg';
import Tcurl1 from './assets/mtitle_curl1.svg';
import Tcurl2 from './assets/mtitle_curl2.svg';
import Tcards from './assets/mtitle_cards.png';
import PrimaryButton from './PrimaryButton';
import Hcard1 from './assets/howto_cardimg1.png';
import Hcard2 from './assets/howto_cardimg2.png';
import Hcard3 from './assets/howto_cardimg3.png';
import Hcard4 from './assets/howto_cardimg4.png';
import Hcurl1 from './assets/mhowto_curl1.svg';
import Hcurl2 from './assets/mhowto_curl2.png';
import Simg1 from './assets/socials_img1.png';
import Simg2 from './assets/socials_img2.png';
import Simg5 from './assets/socials_img4.png';
import Simg4 from './assets/socials_img3.png';
import Simg3 from './assets/socials_img5.png';
import Simg6 from './assets/socials_img6.png';





const MHomePage = () => {


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shiftX, setShiftX] = useState(0);
  let totalCards = 4;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    // Add event listeners
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside);

    // Clean up event listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);


  const handleSwipeEnd = (direction) => {
    let newIndex = currentIndex;
    if (direction === 'left') {
      newIndex = Math.min(currentIndex + 1, totalCards - 1); // Increment index
    } else if (direction === 'right') {
      newIndex = Math.max(currentIndex - 1, 0); // Decrement index
    }
    setCurrentIndex(newIndex);
    setShiftX(0); // Reset shift on swipe completion
  };


  useEffect(() => {
    const container = document.querySelector('.mhowto-cards-cont');

    let startX = 0;
    let startY = 0;
    let endX = 0;
    let isSwiping = false;

    // Touch start
    const touchStartHandler = (e) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      isSwiping = true;
    };

    // Touch move (while swiping)
    const touchMoveHandler = (e) => {
      if (!isSwiping) return;
      endX = e.touches[0].clientX;
      const currentShiftY = e.touches[0].clientY - startY;
      const shift = endX - startX;

      if (Math.abs(shift) > Math.abs(currentShiftY)) {
        e.preventDefault(); // Prevent vertical scroll

        if (currentIndex === 0 && shift > 0) {
          setShiftX(0); // Don't shift if swiping right on the first card
          return;
        }

        // Prevent swipe right on the last card
        if (currentIndex === totalCards - 1 && shift < 0) {
          setShiftX(0); // Don't shift if swiping left on the last card
          return;
        }

        // Shift the container slightly to give a preview effect
        if (Math.abs(shift) < 100) {
          setShiftX(shift); // Shift container left or right based on swipe
        }
      };}

    // Touch end (release)
    const touchEndHandler = () => {
      const shift = endX - startX;
      if (shift > 50) {
        handleSwipeEnd('right'); // Swipe right
      } else if (shift < -50) {
        handleSwipeEnd('left'); // Swipe left
      } else {
        setShiftX(0); // Snap back if swipe is insufficient
      }
      isSwiping = false;
    };

    container.addEventListener('touchstart', touchStartHandler, { passive: true });
    container.addEventListener('touchmove', touchMoveHandler);
    container.addEventListener('touchend', touchEndHandler);

    // Cleanup event listeners on unmount
    return () => {
      container.removeEventListener('touchstart', touchStartHandler);
      container.removeEventListener('touchmove', touchMoveHandler);
      container.removeEventListener('touchend', touchEndHandler);
    };
  }, [currentIndex]);
  
  const [shiftX2, setShiftX2] = useState(0);
  let startX2Ref = useRef(0);  // Use useRef to persist the value between renders
  let currentShiftRef = useRef(0); 
  let startY2Ref = useRef(0);

  useEffect(() => {
    const container = document.querySelector('.msocials-imgs-cont');
  
    const touchStartHandler = (e) => {
      startX2Ref.current = e.touches[0].clientX; // Capture starting touch position
      startY2Ref.current = e.touches[0].clientY; // Capture starting touch position
      currentShiftRef.current = shiftX2; // Store the current shift position
    };
  
    const touchMoveHandler = (e) => {
      const moveX = e.touches[0].clientX - startX2Ref.current; // Calculate horizontal movement
      const moveY = e.touches[0].clientY - startY2Ref.current; // Calculate vertical movement
  
      // Prevent default scrolling if horizontal movement is greater
      if (Math.abs(moveX) > Math.abs(moveY)) {
        e.preventDefault(); // Prevent default touch actions like vertical scrolling
  
        const containerWidth = container.offsetWidth;
        const viewportWidth = window.innerWidth;
        const maxShiftX2 = Math.max(0, containerWidth - viewportWidth);
        let newShiftX2 = currentShiftRef.current + 1.5*moveX;
  
        if (newShiftX2 > 0) {
          setShiftX2(0);
        } else if (newShiftX2 < -maxShiftX2) {
          setShiftX2(-maxShiftX2);
        } else {
          setShiftX2(newShiftX2); // Update shiftX2 based on movement
        }
      }
    };
  
    const touchEndHandler = () => {
      // You can add logic here for when the touch ends if needed
    };

    container.addEventListener('touchstart', touchStartHandler, { passive: true });
    container.addEventListener('touchmove', touchMoveHandler, { passive: false });
    container.addEventListener('touchend', touchEndHandler);

    return () => {
      container.removeEventListener('touchstart', touchStartHandler);
      container.removeEventListener('touchmove', touchMoveHandler);
      container.removeEventListener('touchend', touchEndHandler);
    };
  }, [shiftX2]);

  return (
    <div className="App">
      <section id="m-title-page" className="m-title-page">
        <div className='m-title-page-container'>
          <nav className="navbar" ref={menuRef}>
            <div className="mtitle-secundary-container">
              <Link to="/">
              <img src={Secundary} alt="mtitle Secundary" className="mtitle-secundary" />
              </Link>
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
              {isMenuOpen? <i id="close-icon" className="fas fa-times"></i>:<i id="hamburger-icon" className="fas fa-bars"></i>}
            </div>
            <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
              <li><a href="#section1">Home</a></li>
              <li><a href="#section2">About</a></li>
              <li><a href="#section3">Gallery</a></li>
              <li><a href="#section4">Our vision</a></li>
            </ul>
          </nav>
          <div className="mtitle-curl1-container">
            <img src={Tcurl1} alt="Title Curl1" className="mtitle-curl1"/>
          </div>
          <div className="mtitle-curl2-container">
            <img src={Tcurl2} alt="Title Curl1" className="mtitle-curl2"/>
          </div>
          <div className="mtitle-jointly-cont">
            <span className="mtitle-jointly">Jointly</span>
          </div>
          <div className="mtitle-slogan-cont">
            <span className="mtitle-slogan">Experience it together</span>
          </div>   
          <div className="mtitle-intro-cont">
            <span className="mtitle-intro">The platform for meeting</span>
            <span className="mtitle-intro">the people you want by</span>
            <span className="mtitle-intro">doing the activities you like</span>
          </div>
          <div className="mtitle-cards-container">
            <img src={Tcards} alt="Title Cards" className="mtitle-cards" />
            <div className="mblurred-circle"></div>
          </div>
          <div className="mtitle-button-cont">
            <PrimaryButton text="Join Jointly" width="80%" height="6.5vh" borderRadius={"8px"} />
          </div>
        </div>
      </section>

      <section id="m-howto-page" className="m-howto-page">
        <div className='m-howto-page-container'>
          <div className="mhowto-title-cont">
              <span className="mhowto-title">What is Jointly?</span>
          </div>
          <div className="mhowto-desc-cont">
            <span className="mhowto-desc">Whether you are new in town, bored, or just looking to expand your social circle, Jointly is here for you. <span className="highlight">Find friends nearby, network with new business contacts, or gather a group</span> for a one-time adventure. It's simple!</span>
          </div>

          <div className="mhowto-cards-cont"  style={{ 
            transform: `translateX(calc(-${currentIndex * 25}% + ${shiftX}px))`, 
            transition: shiftX === 0 ? 'transform 0.2s ease-in-out' : 'none'
          }}>
            <div className="mhowto-card-cont">
              <div className="mhowto-card1t-cont">
                <img src={Hcard1} alt="Create small-scale activities on this friendship app and find people to do them with" className="howto-cardimg" />
              </div>
              <div className="mhowto-card1b-cont">
              <div className="mhowto-cardtext-cont">
                <p className="mhowto-cardtext">1. <span className="highlight">Create the activity</span> you want, set the details and decide who can apply</p>
                </div>
              </div>
            </div>

            <div className="mhowto-card-cont">
              <div className="mhowto-card2t-cont">
                <img src={Hcard2} alt="Create small-scale activities on this friendship app and find people to do them with" className="howto-cardimg" />
              </div>
              <div className="mhowto-card1b-cont">
              <div className="mhowto-cardtext-cont">
                <p className="mhowto-cardtext">2. <span className="highlight">Review and accept applications</span> from potential joiners</p>
                </div>
              </div>
            </div>

            <div className="mhowto-card-cont">
              <div className="mhowto-card3t-cont">
                <img src={Hcard3} alt="Create small-scale activities on this friendship app and find people to do them with" className="howto-cardimg" />
              </div>
              <div className="mhowto-card1b-cont">
              <div className="mhowto-cardtext-cont">
                <p className="mhowto-cardtext">3. <span className="highlight">Connect with them</span> in the activity hub and schedule your activity</p>
                </div>
              </div>
            </div>

            <div className="mhowto-card-cont">
              <div className="mhowto-card4t-cont">
                <img src={Hcard4} alt="Create small-scale activities on this friendship app and find people to do them with" className="howto-cardimg" />
              </div>
              <div className="mhowto-card1b-cont">
              <div className="mhowto-cardtext-cont">
                <p className="mhowto-cardtext">4. Enjoy the experience <span className="highlight">together!</span></p>
                </div>
              </div>
            </div>

          </div>

          

          <div className="dots-container">
          {Array.from({ length: totalCards }).map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)} // Clicking on a dot navigates to the corresponding card
            />
          ))}
        </div>

        <div className="mhowto-button-cont">
            <PrimaryButton text="Get Started" width="80%" height="6.5vh" borderRadius={"8px"} />
        </div>
        <div className="mhowto-curl1-container">
          <img src={Hcurl1} alt="Title Curl1" className="mhowto-curl1"/>
        </div>
        <div className="mhowto-curl2-container">
          <img src={Hcurl2} alt="Title Curl1" className="mhowto-curl2"/>
        </div>
        </div>
      </section>

      <section id="m-socials-page" className="m-socials-page">
        <div className='m-socials-page-container'>
          <div className="msocials-title-cont">
            <span className="msocials-title">Jointly in Action -</span>
            <span className="msocials-title">Shared Experiences</span>
          </div>
          <div className="msocials-imgs-cont"  style={{ 
            transform: `translateX(${shiftX2}px)`, 
          }}>
            <div className="msocials-img-cont">
              <img src={Simg1} alt="Meet people during workshops" className="msocials-img" />
            </div>
            <div className="msocials-img-cont">
              <img src={Simg2} alt="Meet people during workshops" className="msocials-img" />
            </div>
            <div className="msocials-img-cont">
              <img src={Simg3} alt="Meet people during workshops" className="msocials-img" />
            </div>
            <div className="msocials-img-cont">
              <img src={Simg4} alt="Meet people during workshops" className="msocials-img" />
            </div>
            <div className="msocials-img-cont">
              <img src={Simg5} alt="Meet people during workshops" className="msocials-img" />
            </div>
            <div className="msocials-img-cont">
              <img src={Simg6} alt="Meet people during workshops" className="msocials-img" />
            </div>
          </div>



        </div>
      </section>

    </div>)}


export default MHomePage;
